<template>
  <div id="Single">
    <div class="row">
      <div class="col-12" v-if="editE">
        <div class="alert alert-success" role="alert">{{ editEM }}</div>
      </div>
      <div class="col-12">
        <div class="card wrapper-text">
          <div class="card-header text-center bg-color-primary-4 text-white">
            <h2 class="m-0 p-0">{{ article.title }}</h2>
          </div>
          <div class="card-body">
            <p class="card-text">{{ article.content }}</p>
          </div>
          <div class="card-footer text-center">
            <router-link
              to="/article"
              class="btn btn-success btn-sm me-1 text-white"
            >
              <span
                class="d-inline-flex align-middle mdi mdi-arrange-send-to-back"
              ></span>
              مشاهده همه نوشته ها
            </router-link>
            <div
              class="btn btn-secondary btn-sm me-1"
              v-if="this.$store.state.auth.isAuthentication"
              @click="editBox = !editBox"
            >
              ویرایش
            </div>
            <div
              class="btn btn-danger btn-sm"
              v-if="this.$store.state.auth.isAuthentication"
              @click="doRemove"
            >
              حذف
            </div>
          </div>
        </div>
      </div>

      <div class="col-12" v-show="editBox">
        <div class="card mt-3">
          <div class="card-header bg-color-primary-3 text-white text-center">
            ویرایش سریع مقاله
          </div>
          <div class="card-body bg-light">
            <form @submit.prevent="doEdit">
              <div class="mb-3">
                <label class="form-label">عنوان مقاله</label>
                <input type="text" class="form-control" v-model="title" />
              </div>
              <div class="mb-3">
                <label class="form-label">توضیحات مقاله</label>
                <textarea
                  class="form-control"
                  rows="2"
                  v-model="description"
                ></textarea>
              </div>
              <div class="mb-3">
                <label class="form-label">متن کامل مقاله</label>
                <textarea
                  class="form-control"
                  rows="8"
                  v-model="content"
                ></textarea>
              </div>
              <div class="text-center">
                <button type="submit" class="btn btn-sm btn-secondary">
                  <span
                    class="d-inline-flex align-middle mdi mdi-content-save me-1"
                  ></span>
                  ثبت ویرایش مقاله
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Single",
  data() {
    let articles = localStorage.getItem("articles");
    articles = JSON.parse(articles);
    let article = articles.find(
      (article) => article.slug === this.$route.params.slug
    );
    return {
      articles: articles,
      article: article,
      editBox: false,
      editE: false,
      editEM: "",
      title: article.title,
      description: article.description,
      content: article.content,
    };
  },
  methods: {
    message() {
      this.editE = true;
      this.editEM = "ویرایش با موفقیت انجام شد.";
    },
    slugify(string) {
      const a =
        "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
      const b =
        "aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
      const p = new RegExp(a.split("").join("|"), "g");

      return (
        string
          .toString()
          .toLowerCase()
          .replace(/\s+/g, "-") // Replace spaces with -
          .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
          .replace(/&/g, "-and-") // Replace & with 'and'
          //.replace(/[^\w-]+/g, "") // Remove all non-word characters
          .replace(/--+/g, "-") // Replace multiple - with single -
          .replace(/^-+/, "") // Trim - from start of text
          .replace(/[~&\\;"',.<>#\s]/g, "") // Replace spaces ~ & \ ; " ' , . < > #
          .replace(/-+$/, "")
      ); // Trim - from end of text
    },
    doEdit() {
      let index = this.articles.findIndex(
        (article) => article.slug === this.$route.params.slug
      );
      this.articles[index] = {
        title: this.title.trim(),
        slug: this.slugify(this.title),
        description: this.description.trim(),
        content: this.content.trim(),
      };
      let editArticle = JSON.stringify(this.articles);
      localStorage.setItem("articles", editArticle);
      this.article = this.articles[index];
      this.editBox = false;
      this.$router.push(`/article/${this.articles[index].slug}`);
      setTimeout(this.message, 2000);
    },
    doRemove() {
      let index = this.articles.findIndex(
        (article) => article.slug === this.$route.params.slug
      );
      this.articles.splice(index, 1);
      let removeArticle = JSON.stringify(this.articles);
      localStorage.setItem("articles", removeArticle);
      this.$router.push("/article");
    },
  },
};
</script>

<style scoped></style>
